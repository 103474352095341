import Vue from 'vue';
import { DateTime as LuxonDateTime } from 'luxon';

import { AlbumModel } from '@/models/user.model';
import './AlbumList.css';
import firebase from '@/services/firebase';
import { ImageLoader } from '@/components/Shared/PictureList/PictureList';
import Loader from '../Loader/Loader';

export interface AlbumListProps {
  albums: AlbumModel[];
  linkSuffix: string;
}

const AlbumList: any = Vue.extend({
  data() {
    return {
      authToken: '',
    };
  },

  methods: {
    getAuthToken() {
      firebase.auth().currentUser?.getIdToken()
        .then((token: string) => {
          this.authToken = token;
        });
    },
  },

  mounted() {
    this.getAuthToken();
  },

  render() {
    const props = this.$attrs as unknown as AlbumListProps;

    const { albums, linkSuffix } = props;


    if (!Array.isArray(albums) || albums.length === 0) {
      return (
        <div class="generic-empty-state">No album found</div>
      );
    }

    if (!this.authToken) {
      return <Loader />;
    }

    return (
      <ul class="album-list">
        {
          albums.map(album => (
            <li key={album.id} class="album-item">
              <router-link to={`${linkSuffix}/${album.id}`}>

                {
                  album.pictures?.[0] && (
                    <ImageLoader
                      token={this.authToken}
                      pic={album.pictures[0]}
                      className="album-item__image" />
                  )
                }

                <div class="album-item__meta-background"></div>
                <div class="album-item__meta-content">
                  <span class="album-item__name">{album.name}</span>

                  <span class="album-item__created">
                    {LuxonDateTime.fromISO(album.created).toLocaleString(LuxonDateTime.DATE_MED)}
                  </span>
                </div>

              </router-link>
            </li>
          ))
        }
      </ul>
    );
  },
});

export default AlbumList;
