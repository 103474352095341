import Vue, { VNode } from 'vue';
import { ApolloError } from 'apollo-boost';
import gql from 'graphql-tag';

import apollo from '@/services/apollo';
import firebase from '@/services/firebase';
import Loader from '@/components/Shared/Loader/Loader';
import { UserModelWithAlbums } from '@/models/user.model';

import './ClientDetails.css';
import Button from '@/components/Admin/Button/Button';
import { getClient } from '@/services/client';
import AlbumList from '@/components/Shared/AlbumList/AlbumList';

export interface ClientDetailsData {
  actions: {
    sendInvitationEmail: boolean;
  };
  albumCreationInProgress: boolean;
  client: UserModelWithAlbums | null;
  clientId: string;
  loading: boolean;
  newAlbumFormData: {
    name: string;
  };
  newAlbumFormError: string;
}

const initialNewAlbumFormData = {
  name: '',
};

const ClientDetails = Vue.extend({
  data(): ClientDetailsData {
    return {
      actions: {
        sendInvitationEmail: false,
      },
      albumCreationInProgress: false,
      client: null,
      clientId: '',
      loading: true,
      newAlbumFormData: { ...initialNewAlbumFormData },
      newAlbumFormError: '',
    };
  },

  methods: {
    handleAddNewAlbum(event: Event) {
      event.preventDefault();

      this.albumCreationInProgress = true;

      const mutation = gql`
        mutation newAlbum(
          $clientId: String!
          $name: String!
        ) {
          newAlbum(
            clientId: $clientId
            name: $name
          ) {
            id
          }
        }
      `;

      apollo.mutate({
        mutation,
        variables: {
          clientId: this.clientId,
          name: this.newAlbumFormData.name,
        },
      })
        .then(({ data }) => {
          this.newAlbumFormData = { ...initialNewAlbumFormData };

          const albumId = data?.newAlbum?.id;

          this.$router.push(`/admin/clients/${this.clientId}/album/${albumId}`);
        })
        .catch((err: ApolloError) => {
          this.newAlbumFormError = err.graphQLErrors.map(({ message }) => message).join(' ');
        })
        .finally(() => {
          this.albumCreationInProgress = false;
        });
    },

    loadClientData() {
      this.loading = true;
      this.clientId = this.$route.params.id;

      getClient(this.clientId, '', { fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.client = data?.getClient || {};
        })
        .catch((err) => {
          console.log('Error loading client => ', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    sendInvitationEmail(event: Event) {
      event.preventDefault();

      this.actions.sendInvitationEmail = true;

      const email = this.client?.email || '';

      if (!email) return;

      firebase.auth().sendPasswordResetEmail(email)
        .catch((err) => {
          console.log('Error sending password reset email => ', err);
        })
        .finally(() => {
          this.actions.sendInvitationEmail = false;
        });
    },

    triggerNewAlbumModal(event: Event) {
      event.preventDefault();

      (this as any).$modal.show('new-album-modal');
    },
  },

  mounted() {
    this.loadClientData();
  },

  name: 'client-details',

  render(): VNode {
    if (this.loading) {
      return (
        <Loader />
      );
    }

    if (!this.client) {
      return (
        // TODO: Style
        <h1>Client not found</h1>
      );
    }

    return (
      <div class="client-details">
        <header class="dashboard-header">
          <h1 class="dashboard-header__title">{this.client.firstName} {this.client.lastName}</h1>
        </header>

        <div class="dashboard-actions">
          <a onClick={(event: Event) => this.triggerNewAlbumModal(event)}>
            <span>+</span>
          </a>

          {/* TODO: Edit */}
        </div>

        <modal name="new-album-modal" height="auto">
          <header class="dashboard-header">
            <h1 class="dashboard-header__title">New Album</h1>
          </header>

          <form onSubmit={this.handleAddNewAlbum} class="dashboard-content">
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" name="name" v-model={this.newAlbumFormData.name} id="name" required />
            </div>

            {
              this.newAlbumFormError && (
                <div class="form-group">
                  <div class="form-error">{this.newAlbumFormError}</div>
                </div>
              )
            }

            <div class="form-group">
              <Button
                loading={this.albumCreationInProgress}
                primary
                type='submit'
              >
                Create Album
            </Button>
            </div>


          </form>
        </modal>

        <div class="dashboard-content">
          <div class="key-value">
            <span class="key">Phone</span>
            <span class="value">{this.client.phone}</span>
          </div>

          <div class="key-value">
            <span class="key">Email</span>
            <span class="value">{this.client.email}</span>
          </div>

          <div class="key-value">
            <span class="key">Actions</span>
            <div class="actions value">
              <a onClick={(event: Event) => this.sendInvitationEmail(event)}>
                <Button loading={this.actions.sendInvitationEmail} primary>
                  Send Invite Email
                  </Button>
              </a>
            </div>
          </div>

          <AlbumList
            albums={this.client.albums}
            linkSuffix={`/admin/clients/${this.clientId}/album`}
          />
        </div>
      </div>
    );
  },

  watch: {
    $route: 'loadClientData',
  },
});

export default ClientDetails;
