import { VNode } from 'vue';

import './Button.css';
import Loader from '@/components/Shared/Loader/Loader';

const Button = (context: any): VNode => {
  const {
    props: {
      danger,
      disabled,
      isLink,
      loading,
      primary,
      small,
      ...props
    },
  } = context;

  const classes = [
    props.class,
    small && 'button__small',
    primary && 'button__primary',
    danger && 'button__danger',
    'button',
  ].filter(i => i).join(' ');

  return isLink
    ? <router-link
      class={classes}
      to={props.to}
      {...props}>{loading ? <Loader small /> : context.children}</router-link>
    : (
      <button
        class={classes}
        disabled={disabled || loading}
        {...props}
      >
        {
          loading
            ? <Loader small />
            : context.children
        }
      </button>);
};

export default Button;
