import gql from 'graphql-tag';
import apollo from '@/services/apollo';

export const getClient = (id: string, email: string = '', queryOptions?: object): Promise<any> => {
  const query = gql`
        query getClient(
          $email: String
          $id: String
        ) {
          getClient (
            email: $email
            id: $id
          ) {
            albums{
              created
              id
              name
              pictures{
                id
                url
              }
            }
            created
            createdBy{
              firstName
              lastName
            }
            email
            id
            firstName
            lastName
            phone
          }
        }
      `;

  return apollo.query({
    ...(queryOptions || {}),
    query,
    variables: {
      email,
      id,
    },
  });
};
